import { apiClient as client } from '@/shared/service/api-client';

export default {
  async getTag(params) {
    return await client.get('/corpTagGroup/get', { params });
  },
  async createTagGroup(params) {
    return await client.post('/corpTagGroup/create', params);
  },
  async updateTagGroup(params) {
    return await client.post('/corpTagGroup/update', params);
  },
  async getList() {
    return await client.get('/corpTagGroup/getList');
  },
  async deleteTagGroup(params) {
    return await client.post('/corpTagGroup/delete', params);
  },
  async createTag(params) {
    return await client.post('/corpTagGroup/createTag', params);
  },
  async deleteTag(params) {
    return await client.post('/corpTagGroup/deleteTag', params);
  },
  async checkTagGroupName(params) {
    return await client.post('/corpTagGroup/checkTagGroupName', params);
  },
  async asyncWorkWechatTag(params) {
    return await client.post('/corpTagGroup/asyncWorkWechatTag', params);
  }
}
