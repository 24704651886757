import {apiClient as client} from '@/shared/service/api-client';

export default {
    async getLastSyncTime(params) {
        return await client.get('/customerManage/getLastSyncTime', {params: params});
    },
    async search(params) {
        return await client.get('/customerManage/search', {params: params});
    },
    async asyncAllCustomers(params) {
      return await client.get('/customerManage/asyncAllCustomers', {params: params});
    },
    async getRelationList(customerId) {
        return await client.get('/customerManage/getRelationList', {params: {customerId: customerId}});
    },
    async getRelation(id) {
        return await client.get('/customerManage/getRelation', {params: {id: id}});
    },
    async saveCustomerCorpTag(params) {
        return await client.post('/customerManage/saveCustomerCorpTag', params);
    },
    async getGroupChatlList(params) {
        return await client.get('/customerManage/getGroupChatList', {params: params});
    },

    // 导出客户
    async asyncExport(params) {
        return await client.get('/customerManage/asyncExport', {params: params});
    },
    async getLastExportStatus(params) {
        return await client.get('/customerManage/getLastExportStatus', {params: params});
    },
}
