<template>
  <a-modal v-model:visible="innerVisible"
           title="选择标签"
           :bodyStyle="{height: '400px', overflowY: 'auto'}"
           @cancel="$emit('update:visible', false)"
           @ok="handleOk">
    <div class="personalTag__tagList"
         v-for="item in personalTagList"
         :key="item.name">
      <div class="tag_label">{{ item.name }}: </div>
      <div class="tag_list">
        <div class="tag"
             v-for="tag in item.corpTagList"
             :key="tag.workWechatThirdAppTagId">
          <a-checkable-tag v-model:checked="tag.checked">
            {{tag.name}}</a-checkable-tag>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import _ from "lodash";
import CorpTag from "@/service/api/corpLag.js";
import customerApi from "@/service/api/customer";

export default {
  name: "CustomerTagModal",
  components: {},
  props: {
    visible: Boolean,
    tags: Array,
    id: Number,
  },
  data() {
    //当前用户的标签信息
    return {
      innerVisible: false,
      tagChecked: [],
      personalTagList: [],
      tagId: [],
      checkedCorpTagList: [],
    };
  },
  watch: {
    visible: function (newVisible) {
      this.innerVisible = newVisible;
      if (newVisible === true) {
        this.getList();
      }
    },
  },
  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      this.personalTagList = await CorpTag.getList();
      const checkedIds = [];
      _.forEach(this.tags, (tag) => {
        checkedIds.push(tag.workWechatThirdAppTagId);
      });

      _.forEach(this.personalTagList, (personalList) => {
        _.forEach(personalList.corpTagList, (tag) => {
          tag.checked = checkedIds.includes(tag.workWechatThirdAppTagId);
        });
      });
    },

    async handleOk() {
      let newCheckedTagList = [];
      _.forEach(this.personalTagList, (personalList) => {
        _.forEach(personalList.corpTagList, (tag) => {
          if (tag.checked) {
            newCheckedTagList.push(tag.workWechatThirdAppTagId);
          }
        });
      });
      const params = {
        relationId: this.id,
        workWechatThirdAppTagIds: newCheckedTagList,
      };
      const res = await customerApi.saveCustomerCorpTag(params);
      if (!res.ok) {
        alert("修改失败，请重试");
        return;
      }
      this.$message.success("修改成功");
      this.$emit("created", res.ok);
      this.$emit("update:visible", false);
    },
  },
};
</script>
